import { React, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Logo from "../images/404.svg";
import Background from "../images/bg.svg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  useEffect(() => {
    // for window scrolldown event
    window.onscroll = function () {
      if (window.scrollY >= 45) {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "fixed";
        document.querySelector("main").style.marginTop = "45px";
      } else {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "relative";
        document.querySelector("main").style.marginTop = "0px";
      }
      let mybtn = document.getElementById("back-to-top");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }
    };
  });

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <section
        className="hero is-white is-large"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <img
              src={Logo}
              id="nav-logo"
              alt="logo"
              width="auto"
              style={{ maxWidth: "300px", minWidth: "200px" }}
            />
            <h1 className="title">Not Found</h1>
            <p className="subtitle">
              You just hit a route that doesn&#39;t exist... the sadness sorry!
            </p>
            <p></p>
            <br />
            <a href="/" className="button is-black is-outlined">
              <b>Go back</b>&nbsp;🍄
            </a>
          </div>
        </div>

        <div className="hero-foot"></div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
